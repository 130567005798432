import React from 'react'
import { string } from 'prop-types'

import Logo from '@/component/Primitive/Logo'
import SmartLink from '@/component/Primitive/SmartLink'

const HeaderLogo = ({ href }) => (
  <SmartLink to="/section" as={href}>
    <h1>
      <Logo width={88} animated interactive />
    </h1>
  </SmartLink>
)

HeaderLogo.propTypes = {
  href: string.isRequired
}

export default HeaderLogo
