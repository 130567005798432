import React from 'react'
import { node, object, string, func } from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './SiteContainer.module.scss'

import A11yNavigation from '@/component/Primitive/A11yNavigation'
import Background from '../Background'
import Header from '../Header'
import LanguageSelector from '../LanguageSelector'
import Main from '../Main'
import Footer from '../Footer'
import FooterBackground from '@/component/Primitive/FooterBackground'

const SiteContainer = ({
  children,
  color1,
  color2,
  currentPath,
  instance,
  section,
  __url
}) => {
  const { t } = useTranslation()

  const isHomepage = currentPath?.split('/').length <= 2
  const isProduct =
    currentPath?.split('/')[2] === 'product' ||
    currentPath?.split('/')[1] === 'product'

  return (
    <div className={styles.SiteContainer}>
      {isHomepage && <FooterBackground />}
      <A11yNavigation>
        <a href="#content">{t('A11yNavContentLink')}</a>
        <a href="#navigation">{t('A11yNavPrimaryNavigationLink')}</a>
      </A11yNavigation>
      {(isHomepage || isProduct || section.displayGeolocationBar) && (
        <LanguageSelector
          __url={__url}
          currentPath={currentPath}
          displayFlag={section.displayFlag}
          languageCode={instance.languageCode}
          translationOptions={instance.translationOptions}
        />
      )}
      <Background color1={color1} color2={color2} />
      <Header
        navItems={instance.navItems}
        subNavItems={section.subNavItems}
        currentPath={currentPath}
        __url={__url}
        isJP={instance?.languageCode === 'jp-JP'}
      />
      <Main isJP={instance?.languageCode === 'jp-JP'}>{children}</Main>
      <Footer
        navItems={instance.footerNavItems}
        currentPath={currentPath}
        instanceSlug={instance?.slug}
        privacySection={instance?.privacySection?.[0]}
        isJP={instance?.languageCode === 'jp-JP'}
      />
    </div>
  )
}

SiteContainer.defaultProps = {
  instance: {},
  section: {},
  __url: (path) => path
}

SiteContainer.propTypes = {
  children: node.isRequired,
  color1: string,
  color2: string,
  currentPath: string,
  instance: object,
  section: object,
  __url: func.isRequired
}

export default SiteContainer
