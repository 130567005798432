import React, { useRef, useState } from 'react'
import { array, bool, func, string } from 'prop-types'
import classNames from 'classnames'
import useEscapeKey from '@/hook/useEscapeKey'
import useOutsideClick from '@/hook/useOutsideClick'
import { useTranslation } from 'react-i18next'

import styles from './Dropdown.module.scss'

import Icon from '@/component/Primitive/Icon'
import ShrinkWrap from '@/component/Primitive/ShrinkWrap'

const Dropdown = ({ alignRight, block, items, onChange, value }) => {
  const [expanded, setExpanded] = useState(false)
  const ref = useRef()
  const { t } = useTranslation()

  const toggleExpanded = () => {
    setExpanded((prevExpanded) => !prevExpanded)
  }

  const handleClose = () => {
    setExpanded(false)
  }

  const handleSelection = (item) => {
    handleClose()
    onChange && onChange(item)
  }

  useEscapeKey(expanded && handleClose)
  useOutsideClick(ref, expanded && handleClose)

  const currentItem = items.find((item) => item.value === value) || items[0]
  const hasMultipleItems = items.length > 1

  return (
    <div
      className={classNames(
        styles.Dropdown,
        alignRight && styles.alignRight,
        block && styles.block,
        expanded && styles.expanded
      )}
      ref={ref}
    >
      <div className={styles.DropdownList}>
        <button
          type="button"
          className={styles.DropdownListItem}
          {...(hasMultipleItems && { onClick: toggleExpanded })}
          {...(!hasMultipleItems && { disabled: true })}
        >
          <ShrinkWrap fullWidth>
            <ShrinkWrap.Item vAlign="middle">
              {currentItem.text}
            </ShrinkWrap.Item>
            {hasMultipleItems && (
              <ShrinkWrap.Item vAlign="middle" shrink>
                <Icon
                  type="expand-more"
                  a11yText={
                    expanded
                      ? t('DropdownHideContent')
                      : t('DropdownRevealContent')
                  }
                  className={styles.FaqIndicator}
                />
              </ShrinkWrap.Item>
            )}
          </ShrinkWrap>
        </button>
      </div>

      {expanded && (
        <ul
          className={classNames(
            styles.DropdownList,
            styles.DropdownListOverlay
          )}
        >
          {items.map((item, i) => {
            return (
              <li key={`dropdown-item-${i}`}>
                <button
                  className={classNames(
                    styles.DropdownListItem,
                    item.value === value && styles.selected
                  )}
                  onClick={() => handleSelection(item)}
                >
                  {item.text}
                </button>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

Dropdown.defaultProps = {
  currentIndex: 0
}

Dropdown.propTypes = {
  alignRight: bool,
  block: bool,
  items: array.isRequired,
  onChange: func,
  parentRef: func,
  value: string
}

export default Dropdown
