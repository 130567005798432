import React, { useState } from 'react'
import classNames from 'classnames'
import { array, bool, func, node, string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useVersionPath } from '@/component/Context/VersionPathContext'
import useMedia from '@/hook/useMedia'

import styles from './LanguageBanner.module.scss'

import ButtonStandard from '../ButtonStandard'
import Flag from '../Flag'
import Dropdown from '../Dropdown'
import ShrinkWrap from '../ShrinkWrap'
import Type from '../Type'
import Icon from '@/component/Primitive/Icon'
import SmartLink from '@/component/Primitive/SmartLink'

const LanguageBanner = ({
  children,
  countries,
  onSubmit,
  languageCode,
  isJP,
  displayFlag,
  __url
}) => {
  const { t } = useTranslation()
  const versionPath = useVersionPath()
  const isTablet = useMedia('(max-width: 600px)')
  const [value, setValue] = useState(languageCode)

  const dropdownOptions = countries.map((country) => ({
    value: country.languageCode,
    text: (
      <ShrinkWrap>
        <ShrinkWrap.Item shrink vAlign="middle">
          <Flag
            uri={versionPath('/public/image/structure/globe.svg')}
            children={country.name}
            languageCode={country.languageCode}
          />
        </ShrinkWrap.Item>
        <ShrinkWrap.Item vAlign="middle">{country.name}</ShrinkWrap.Item>
      </ShrinkWrap>
    )
  }))

  const handleChange = (item) => {
    setValue(item.value)
    if (isTablet) {
      onSubmit(item.value)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit(value)
  }
  const freeResourcesLink = __url && __url('/free-resources')

  return (
    <div
      className={classNames(styles.LanguageBannerContainer, isJP && styles.JP)}
    >
      {displayFlag && (
        <SmartLink to={freeResourcesLink} className={styles.FreeResourcesFlag}>
          <Icon
            width={130}
            height={72}
            type="free-resources-flag"
            className={styles.FreeResourcesFlagIcon}
            a11yText=""
          />
        </SmartLink>
      )}
      <form
        data-testid="form"
        className={classNames(
          styles.LanguageBanner,
          styles.LanguageBannerWithFlag
        )}
        onSubmit={handleSubmit}
      >
        <Type size="small">{children}</Type>
        <div className={styles.LanguageBannerDropdown}>
          <Dropdown
            block
            active
            items={dropdownOptions}
            onChange={handleChange}
            value={value}
          />
        </div>
        {!isTablet && (
          <div className={styles.LanguageBannerButton}>
            <ButtonStandard data-testid="button" small type="submit">
              {t('LanguageBannerCta')}
            </ButtonStandard>
          </div>
        )}
      </form>
    </div>
  )
}

LanguageBanner.propTypes = {
  children: node,
  countries: array.isRequired,
  displayFlag: bool,
  onSubmit: func.isRequired,
  languageCode: string,
  isJP: bool,
  __url: func
}

export default LanguageBanner
