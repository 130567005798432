import React from 'react'
import { number } from 'prop-types'
import createQuery from '../src/query/instance/instance'
import retrieveData from '../src/lib/retrieve-data'

import SmartLink from '@/component/Primitive/SmartLink'
import Container from '@/component/Primitive/Container'
import Stack from '@/component/Primitive/Stack'
import Type from '@/component/Primitive/Type'
import Prose from '@/component/Primitive/Prose'
import Panel from '@/component/Primitive/Panel'

const ErrorPage = ({ statusCode }) => (
  <Stack gap="large">
    <Container size="wide" gutter center>
      <Panel>
        <Container size="medium" gutter center>
          <Stack size="large">
            <Type as="h1" size="heading2" color="purple">
              {statusCode
                ? `An error occurred (${statusCode})`
                : 'An error occurred'}
            </Type>
            <Prose>
              <p>
                Why not try heading back to the{' '}
                <SmartLink to="/section" as="/">
                  homepage
                </SmartLink>
                .
              </p>
            </Prose>
          </Stack>
        </Container>
      </Panel>
    </Container>
  </Stack>
)

ErrorPage.getInitialProps = async (context) => {
  const { req, res, err } = context
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404

  const query = createQuery()
  const { data } = await retrieveData(context, query)
  const instance =
    (data && data.instance) || (req && req.instance) || context.instance
  return { ...data, statusCode, instance }
}

ErrorPage.propTypes = {
  statusCode: number
}

export default ErrorPage
