const instancifyUrl = (instance) => {
  const format = (url) => {
    // If the url starts with http(s) then don't modify it.
    if (url && url.indexOf('http') === 0) return url

    // If the url doesn't start with a slash then add one to beginning
    if (!/^\//.test(url)) url = '/' + url

    if (instance && instance.slug) {
      // If the url is just the current instance slug, then don't add the slug to the beginning
      if (url === '/' + instance.slug) return url

      // If the url starts with the current slug don't modify it.
      if (url && url.indexOf('/' + instance.slug + '/') === 0) return url

      // Prefix the slug to the url and return.
      return '/' + instance.slug + url
    }
    return url
  }

  return format
}

module.exports = instancifyUrl
