import React from 'react'
import { bool, node } from 'prop-types'
import classNames from 'classnames'

import styles from './Main.module.scss'

const Main = ({ children, spacingTop, isJP }) => (
  <main
    id="content"
    role="main"
    className={classNames(
      styles.Main,
      spacingTop && styles.spacingTop,
      isJP && styles.JP
    )}
  >
    {children}
  </main>
)

Main.propTypes = {
  children: node.isRequired,
  spacingTop: bool,
  isJP: bool
}

export default Main
