import React, { useEffect, useRef, useState } from 'react'
import { node } from 'prop-types'
import classNames from 'classnames'

import styles from './ScrollNavWrapper.module.scss'
import Icon from '@/component/Primitive/Icon'

const ScrollNavWrapper = ({ children }) => {
  const [isScrolling, setIsScrolling] = useState(false)
  const [overflowingLeft, setOverflowingLeft] = useState(false)
  const [overflowingRight, setOverflowingRight] = useState(false)

  const scrollWrapper = useRef()
  const scrollInner = useRef()

  useEffect(() => {
    if (typeof window === 'undefined') return

    // const wrapper = scrollWrapper.current
    const inner = scrollInner.current

    function setIndicators() {
      // Check if we actually need scrolling
      if (inner.scrollWidth <= inner.offsetWidth) return

      if (!isScrolling) {
        window.requestAnimationFrame(function () {
          // Should we show the "scroll-left" indicator?
          if (inner.scrollLeft > 0) {
            setOverflowingLeft(true)
          } else {
            setOverflowingLeft(false)
          }

          // Should we show the "scroll-right" indicator?
          if (inner.scrollLeft < inner.scrollWidth - inner.offsetWidth) {
            setOverflowingRight(true)
          } else {
            setOverflowingRight(false)
          }

          setIsScrolling(false)
        })
        setIsScrolling(true)
      }
    }

    inner.addEventListener('scroll', setIndicators)
    window.addEventListener('resize', setIndicators)
    setIndicators() // Run once on load to get initial indicators

    return () => {
      inner.removeEventListener('scroll', setIndicators)
      window.removeEventListener('resize', setIndicators)
    }
  }, [isScrolling])

  return (
    <div
      className={classNames(
        styles.ScrollNav,
        overflowingLeft && styles.overflowingLeft,
        overflowingRight && styles.overflowingRight
      )}
      ref={scrollWrapper}
    >
      <ul className={styles.ScrollNavList} ref={scrollInner}>
        {children}
      </ul>

      <div className={styles.ScrollNavIndicators}>
        <div className={styles.ScrollNavIndicator}>
          <Icon type="navigate-previous" width={32} a11yText="" />
        </div>
        <div className={styles.ScrollNavIndicator}>
          <Icon type="navigate-next" width={32} a11yText="" />
        </div>
      </div>
    </div>
  )
}

ScrollNavWrapper.propTypes = {
  children: node.isRequired
}

export default ScrollNavWrapper
