import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import masterTranslations from '../master-translations-en-gb.json'
import devTranslations from '../master-translations-dev.json'

i18n.use(initReactI18next).init({
  initImmediate: true,
  defaultNS: ['common'],
  resources: {
    en_GB: masterTranslations,
    dev: devTranslations
  },
  fallbackLng: 'en_GB',
  lng: 'en_GB',
  languages: ['en_GB', 'dev'],
  supportedLngs: null,
  interpolation: {
    escapeValue: false
  },
  react: {}
})

export default i18n
