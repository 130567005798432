const createQuery = () => {
  const query = `query Instance($url:String!) {
    instance(url:$url) {
      _id
      slug
      navItems {
        link
        name
      }
      footerNavItems {
        link
        name
      }
    }
  }`
  return query
}
export default createQuery
