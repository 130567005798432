import React from 'react'
import { bool, number } from 'prop-types'
import classNames from 'classnames'
import ratioScaler from '@/lib/ratio-scaler'

import styles from './Logo.module.scss'

import VisuallyHidden from '../VisuallyHidden'

const Logo = ({ width, height, interactive, animated }) => {
  const targetDimensions = { width, height }
  const nativeDimensions = { width: 600, height: 580 }
  const ratioDimensions = ratioScaler(
    targetDimensions,
    nativeDimensions,
    'ceil'
  )
  const showParts = animated || interactive
  return (
    <div
      className={classNames(
        styles.Logo,
        animated && styles.animated,
        interactive && styles.interactive
      )}
      style={{
        width: `${ratioDimensions.width}px`,
        height: `${ratioDimensions.height}px`
      }}
    >
      {!showParts && (
        <div className={classNames(styles.LogoPart, styles.LogoFull)} />
      )}
      {showParts && (
        <>
          <div className={classNames(styles.LogoPart, styles.LogoBottom)} />
          <div className={classNames(styles.LogoPart, styles.LogoMiddle)}>
            <div className={classNames(styles.LogoPart, styles.LogoEyes)} />
          </div>
          <div className={classNames(styles.LogoPart, styles.LogoTop)} />
        </>
      )}
      <VisuallyHidden>Nessy</VisuallyHidden>
    </div>
  )
}

Logo.propTypes = {
  width: number,
  height: number,
  interactive: bool,
  animated: bool
}

export default Logo
