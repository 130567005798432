import React from 'react'
import { node, string } from 'prop-types'

import styles from './Flag.module.scss'

import VisuallyHidden from '../VisuallyHidden'

const Flag = ({ children, languageCode, uri }) => (
  <div
    className={styles.Flag}
    {...(children && { lang: languageCode })}
    style={{ backgroundImage: `url(${uri})` }}
  >
    {children && <VisuallyHidden>{children}</VisuallyHidden>}
  </div>
)

Flag.propTypes = {
  children: node,
  languageCode: string,
  uri: string.isRequired
}

export default Flag
