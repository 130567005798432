import React, { useEffect, useRef } from 'react'
import classNames from 'classnames'
import { bool, func, node } from 'prop-types'
import scrollIntoView from 'scroll-into-view'

import styles from './ScrollNavItem.module.scss'

import SmartLink from '../../../SmartLink'

const ScrollNavItem = ({ active, children, onClick, ...other }) => {
  const ref = useRef(null)

  const handleClick = () => {
    // Once Safari supports this, we can ditch the 'scroll-into-view' npm module in favour of this line
    // ref.current.scrollIntoView({ behavior: 'smooth', inline: 'center' })
    scrollIntoView(ref.current, { time: 200, align: { left: 0.5 } })
    onClick && onClick()
  }

  useEffect(() => {
    active && scrollIntoView(ref.current, { time: 0 })
  })

  return (
    <li className={styles.ScrollNavItem} ref={ref}>
      <SmartLink
        className={classNames(
          styles.ScrollNavItemLink,
          active && styles.active
        )}
        data-text={children}
        onClick={handleClick}
        {...other}
      >
        <span className={styles.ScrollNavItemLinkInner}>{children}</span>
      </SmartLink>
    </li>
  )
}

ScrollNavItem.displayName = 'ScrollNavItem'

ScrollNavItem.propTypes = {
  active: bool,
  children: node.isRequired,
  onClick: func
}

export default ScrollNavItem
