import React from 'react'
import { string } from 'prop-types'
import color from 'color'
import minifyCssString from '@/lib/css-string-minifier'

const RootColorVariables = ({ primary, secondary, accent }) => {
  const formattedPrimary = color(primary)
  const formattedSecondary = color(secondary)

  return (
    <style
      dangerouslySetInnerHTML={{
        __html: minifyCssString(`
          :root{
            --color-background: ${primary};
            --color-background-dark: ${formattedPrimary
              .desaturate(0.7)
              .darken(0.5)
              .hex()};
            --color-background-secondary: ${secondary || primary};
            --color-background-secondary-dark: ${
              secondary
                ? formattedSecondary.desaturate(0.7).darken(0.5).hex()
                : formattedPrimary.desaturate(0.7).darken(0.5).hex()
            };
            ${accent && `--color-section-accent: ${accent};`}
          }
        `)
      }}
    />
  )
}

RootColorVariables.propTypes = {
  primary: string.isRequired,
  secondary: string,
  accent: string
}

export default RootColorVariables
