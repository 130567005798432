import React from 'react'
import { func, object } from 'prop-types'
import NextApp from 'next/app'
import { NextSeo } from 'next-seo'
import withError from 'next-with-error'
import ErrorPage from './_error'
import * as Sentry from '@sentry/browser'
import packageJson from '../../package.json'
import staticFileMap from '../static-file-map.json'

import { AccentColorContextProvider } from '@/component/Context/AccentColorContext'
import { VersionPathContextProvider } from '@/component/Context/VersionPathContext'
import SiteContainer from '@/component/Structure/SiteContainer'
import RootColorVariables from '@/component/Structure/RootColorVariables'

import createConfig from '../site.config'
import createUrlInstancifier from '../../components/site/next/lib/url-instancifier'
import createCdnVersionPath from '../../components/site/next/lib/cdn-version-path'

import '../i18n'
import { withTranslation } from 'react-i18next'

import '@/asset/scss/base.scss'

const config = createConfig()
const versionPath = createCdnVersionPath(staticFileMap, config.cdnDomain)

class App extends NextApp {
  constructor(props) {
    super(props)
    const {
      pageProps: { instance = {} },
      i18n
    } = props
    const { languageCode, translations = {} } = instance
    i18n.addResources(languageCode, 'common', translations.common)
    i18n.changeLanguage(languageCode)
  }

  componentDidMount() {
    if (process.env.NODE_ENV !== 'development') {
      Sentry.init({
        dsn: config.sentryDsn,
        environment: process.env.SENTRY_ENV,
        release: packageJson.version
      })
      Sentry.configureScope((scope) => {
        scope.setTag('application', 'browser')
      })
    } else {
      window.Sentry = {
        captureException: (...args) => console.error(...args)
      }
    }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key])
      })

      Sentry.captureException(error)
    })

    super.componentDidCatch(error, errorInfo)
  }

  render() {
    const { Component, pageProps, router } = this.props
    const { instance, searchTerm, section, product } = pageProps
    const color1 = product?.color1
      ? `#${product?.color1}`
      : section?.color1
      ? `#${section.color1}`
      : '#F2E5BA'
    const color2 = product?.color2
      ? `#${product?.color2}`
      : section?.color2
      ? `#${section.color2}`
      : null
    const colorAccent = product?.accentColor
      ? `#${product?.accentColor}`
      : section?.accentColor
      ? `#${section.accentColor}`
      : null
    const __url = createUrlInstancifier(instance)
    return (
      <VersionPathContextProvider versionPath={versionPath}>
        <AccentColorContextProvider color={color1}>
          <RootColorVariables
            primary={color1}
            secondary={color2}
            accent={colorAccent}
          />
          <SiteContainer
            currentPath={router.asPath}
            instance={instance}
            searchTerm={searchTerm}
            section={section}
            color1={color1}
            color2={color2}
            __url={__url}
          >
            <NextSeo {...config.meta} />
            <Component {...pageProps} __url={__url} />
          </SiteContainer>
        </AccentColorContextProvider>
      </VersionPathContextProvider>
    )
  }
}

App.propTypes = {
  Component: func,
  pageProps: object
}

const ErrorWrappedApp = withError((props) => <ErrorPage {...props} />)(App)
export default withTranslation()(ErrorWrappedApp)
