import React from 'react'
import Icon from '../Icon'

import styles from './FooterBackground.module.scss'

const FooterBackground = () => {
  return (
    <>
      <div className={styles.FooterBackground} />
      <Icon type="seabed" width="auto" className={styles.FbBg} a11yText="" />
      <img src="/image/Seaweed.png" alt="" className={styles.FbSeaweed} />
      <img src="/image/Seaweed2.png" alt="" className={styles.FbSeaweed2} />
      <img src="/image/Submarine.png" alt="" className={styles.FbSubmarine} />
      <img src="/image/Jellyfish.png" alt="" className={styles.FbJellyfish} />
      <img src="/image/CoralLeft.png" alt="" className={styles.FbCoral} />
      <img src="/image/CoralRight.png" alt="" className={styles.FbCoral2} />
    </>
  )
}

export default FooterBackground
