import React from 'react'
import { string } from 'prop-types'
import minifyCssString from '@/lib/css-string-minifier'

import styles from './Background.module.scss'

const Background = ({ color1, color2 }) => (
  <div
    className={styles.Background}
    style={{
      ...(color1 && { backgroundColor: color1 }),
      ...(color1 &&
        color2 && {
          backgroundImage: minifyCssString(`linear-gradient(
            to bottom,
            ${color1},
            ${color1} 45%,
            ${color2} 85%,
            ${color2}
          )`)
        })
    }}
  />
)

Background.propTypes = {
  color1: string,
  color2: string
}

export default Background
