import React, { useEffect, useState } from 'react'
import { arrayOf, func, object, string } from 'prop-types'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'

import styles from './SearchOverlay.module.scss'

import Icon from '../Icon'
import Spinner from '../Spinner'

const SearchOverlay = ({
  onChange,
  onSubmit,
  autocompletions,
  setRef,
  suggestions,
  __url
}) => {
  const router = useRouter()
  const [value, updateValue] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const { t } = useTranslation()

  const handleChange = (e) => {
    updateValue(e.target.value)
  }

  const handleClick = (e) => {
    updateValue(e.target.textContent)
    setSubmitted(true)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setSubmitted(true)
    onSubmit && onSubmit(e)
    router.push({
      pathname: __url('/search'),
      query: { q: value }
    })
  }

  useEffect(() => {
    onChange && onChange(value)

    if (submitted) {
      onSubmit && onSubmit()
      // setSubmitted(false)
    }
  }, [onChange, onSubmit, submitted, value])

  return (
    <form
      action={__url('/search')}
      className={styles.SearchOverlay}
      onSubmit={handleSubmit}
      ref={setRef}
    >
      <div className={styles.SearchOverlayControlWrapper}>
        <input
          className={styles.SearchOverlayControl}
          type="text"
          name="q"
          onChange={handleChange}
          value={value}
          placeholder={t('SearchPlaceholder')}
          // This is an acceptable autoFocus use-case, as the User will have
          // just manually triggered the search form, so will be expecting the
          // focus jump.
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
        <button className={styles.SearchOverlaySubmit} type="submit">
          {submitted && <Spinner />}
          {!submitted && <Icon type="search" a11yText={t('SearchButton')} />}
        </button>
      </div>
      {autocompletions.length > 0 && (
        <ul className={styles.SearchOverlayList}>
          {autocompletions.map((item) => (
            <li key={`autocompletion-${item}`}>
              <button
                type="button"
                onClick={handleClick}
                className={styles.SearchOverlayButton}
              >
                <Icon type="search" a11yText="" /> {item}
              </button>
            </li>
          ))}
        </ul>
      )}
      {suggestions.length > 0 && (
        <section>
          <h3 className={styles.SearchOverlayHeading}>
            {t('SearchSuggestions')}
          </h3>
          <ul className={styles.SearchOverlayList}>
            {suggestions.map((item) => (
              <li key={`autocompletion-${item}`}>
                <button
                  type="button"
                  onClick={handleClick}
                  className={styles.SearchOverlayButton}
                >
                  <Icon type="search" a11yText="" /> {item}
                </button>
              </li>
            ))}
          </ul>
        </section>
      )}
    </form>
  )
}

SearchOverlay.defaultProps = {
  autocompletions: [],
  suggestions: []
}

SearchOverlay.propTypes = {
  onChange: func,
  onSubmit: func,
  autocompletions: arrayOf(string),
  setRef: object,
  suggestions: arrayOf(string),
  __url: func.isRequired
}

export default SearchOverlay
