import React, { createContext, useContext } from 'react'
import { node, func } from 'prop-types'

const VersionPathContext = createContext((p) => p)

const useVersionPath = () => useContext(VersionPathContext)

const VersionPathContextProvider = ({ children, versionPath }) => (
  <VersionPathContext.Provider value={versionPath}>
    {children}
  </VersionPathContext.Provider>
)

VersionPathContextProvider.propTypes = {
  children: node.isRequired,
  versionPath: func
}

const VersionPathContextConsumer = VersionPathContext.Consumer

export {
  VersionPathContext,
  VersionPathContextProvider,
  VersionPathContextConsumer,
  useVersionPath
}
