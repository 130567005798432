const createMapped = require('versionator/lib/middleware/mapped')

const createCdnVersionPathUrl = (staticFileMap, cdnDomain = '') => {
  const { versionPath } = createMapped(staticFileMap)
  return (path) => {
    if (!path) return path

    const versionedUrl = versionPath(path)

    if (versionedUrl === path) {
      // didnt change, we haven't got that asset in the static map, ignore
      return versionedUrl
    }

    // If the url starts with http(s) then don't modify it.
    if (path.indexOf('http') === 0) return path

    // prefix with cdn domain
    return cdnDomain + versionedUrl
  }
}

module.exports = createCdnVersionPathUrl
