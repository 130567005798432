import React, { useEffect, useState } from 'react'
import { shape, string, arrayOf, func, bool } from 'prop-types'
import Router from 'next/router'
import { useTranslation } from 'react-i18next'

import LanguageBanner from '@/component/Primitive/LanguageBanner'

const LanguageSelector = ({
  languageCode,
  translationOptions,
  displayFlag,
  currentPath,
  __url
}) => {
  const [mounted, setMounted] = useState(false)
  const { t } = useTranslation()
  useEffect(() => setMounted(true), [])
  const isJP = languageCode === 'jp-JP'

  const getRedirectPath = (selectedLanguageCode) => {
    const pathSegments = currentPath?.split('/').slice(1)

    const isHome = pathSegments.length === 1 // only /en-gb
    const isStore = pathSegments.length === 2 && pathSegments[1] === 'shop' // /en-gb/shop
    const isProduct = pathSegments.length === 3 && pathSegments[1] === 'product' // /en-gb/product/some-slug
    const isGoingToJP = selectedLanguageCode === 'jp-JP'

    // pages like /en-gb go to /<new>
    if (isHome) return ''
    // pages like /en-gb/shop go to /<new>/shop
    if (isStore) return pathSegments.slice(1).join('/')

    // pages like /en-gb/product/some-slug go to /<new>/product/some-slug
    // but NOT for JP
    if (isProduct) {
      if (isJP) {
        return 'shop'
      }
      if (isGoingToJP) {
        return 'shop'
      }
      return pathSegments.slice(1).join('/')
    }

    // any other page will take the user to /<new>
    return ''
  }

  const handleSubmit = (value) => {
    const selectedInstance = translationOptions.find(
      ({ languageCode }) => languageCode === value
    )

    const redirectPath = getRedirectPath(selectedInstance?.languageCode)
    Router.push(`/${selectedInstance.slug}/${redirectPath}`)
  }

  if (!mounted) return null

  return (
    <LanguageBanner
      __url={__url}
      displayFlag={displayFlag}
      countries={translationOptions}
      languageCode={languageCode}
      onSubmit={handleSubmit}
      isJP={isJP}
    >
      {t('LanguageBannerText')}
    </LanguageBanner>
  )
}

LanguageSelector.propTypes = {
  __url: func,
  currentPath: string,
  displayFlag: bool,
  languageCode: string.isRequired,
  translationOptions: arrayOf(
    shape({
      name: string.isRequired,
      languageCode: string.isRequired,
      flagUrl: string.isRequired
    })
  ).isRequired
}

export default LanguageSelector
