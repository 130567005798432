import React from 'react'
import classNames from 'classnames'
import { bool, node } from 'prop-types'

import styles from './NavigationItem.module.scss'

import SmartLink from '@/component/Primitive/SmartLink'

const NavigationItem = ({
  children,
  active,
  desktopOnly,
  primary,
  saveSpace,
  ...other
}) => (
  <li
    className={classNames(
      styles.NavigationItem,
      primary && styles.primary,
      desktopOnly && styles.desktopOnly
    )}
  >
    <SmartLink
      className={classNames(
        styles.NavigationItemLink,
        active && styles.active,
        saveSpace && styles.saveSpace
      )}
      {...other}
    >
      {children}
    </SmartLink>
  </li>
)

NavigationItem.propTypes = {
  active: bool,
  desktopOnly: bool,
  children: node.isRequired,
  primary: bool,
  saveSpace: bool
}

export default NavigationItem
