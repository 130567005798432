import ScrollNavWrapper from './component/ScrollNavWrapper'
import ScrollNavItem from './component/ScrollNavItem'

const ScrollNav = ScrollNavWrapper
ScrollNav.displayName = 'ScrollNav'

ScrollNav.Item = ScrollNavItem
ScrollNav.Item.displayName = 'ScrollNav.Item'

export default ScrollNav
